import React, { useState, useEffect, useCallback } from 'react'
import client from '../../../api/client'
import { Branches, Banners, BranchModal, ShipmentTypes } from '../../../components/admin'
import { Helmet } from 'react-helmet'
import { Row, Col, Button, Modal, notification, Form } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import MessageTemplate from '../../../components/admin/configuration/messageTemplate'
import EmailTemplate from '../../../components/admin/configuration/emailTemplate'
import { getLocalStorageItem } from '../../../constants/helper-functions'
const subMenus = [
  {
    name: 'Баннерын жагсаалт',
    dataIndex: 'banners',
  },
  {
    name: 'Салбарын мэдээлэл',
    dataIndex: 'branches',
  },
  {
    name: 'Илгээмжийн төрөл',
    dataIndex: 'shipmentTypes',
  },
  {
    name: 'Мессежийн загвар',
    dataIndex: 'messageTemplate',
  },
  {
    name: 'И-мейлийн загвар',
    dataIndex: 'emailTemplate',
  },
]

const Configuration = () => {
  const [addBranchFormRef] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [addBranchModalVisible, setAddBranchModalVisible] = useState(false)
  const [isReceiveOnline, setIsReceiveOnline] = useState(false)
  const [activeSubMenu, setActiveSubMenu] = useState('banners')
  const [bannersInfo, setBannersInfo] = useState({
    tableData: [],
    dataCount: 0,
  })
  const [branchesInfo, setBranchesInfo] = useState({
    tableData: [],
    dataCount: 0,
  })

  const [staffList, setStaffList] = useState([])

  useEffect(() => {
    client.get('/api/admin/employees').then((res) => {
      if (res.status === 200) {
        setStaffList(res.data)
      }
    })
  }, [])

  const fetchTableData = useCallback(() => {
    let endpoint = '/api/lookup/banners'
    let state = setBannersInfo
    if (activeSubMenu === 'branches') {
      endpoint = '/api/lookup/branches/account'
      state = setBranchesInfo
    }
    client
      .get(endpoint)
      .then(async (res) => {
        await state({
          tableData: res.data,
          dataCount: res.data.length,
        })
      })
      .catch((err) => console.error({ err }))
      .then(() => setLoading(false))
  }, [activeSubMenu])

  useEffect(() => {
    setLoading(true)
    fetchTableData()
  }, [fetchTableData])

  const handleDeleteBanner = (id) => {
    Modal.confirm({
      title: 'Та энэ баннерыг устгахдаа итгэлтэй байна уу?',
      icon: <ExclamationCircleOutlined />,
      className: 'sb-modal',
      okText: 'Устгах',
      okType: 'danger',
      cancelText: 'Цуцлах',
      centered: true,
      onOk: () => {
        let endpoint = `/api/lookup/banners/${id}`
        client
          .delete(endpoint)
          .then(async (res) => {
            fetchTableData()
            notification.success({ message: 'Баннер амжилттай устгагдлаа!' })
          })
          .catch((err) => console.error({ err }))
      },
    })
  }

  const addBranch = async (values) => {
    const endpoint = `/api/lookup/branches`
    const data = {
      ...values,
    }
    data.isReceiveOnline = isReceiveOnline

    client
      .post(endpoint, data)
      .then(async (res) => {
        fetchTableData()
        notification.success({
          message: 'Шинэ салбар амжилттай нэмэгдлээ!',
        })
        setAddBranchModalVisible(false)
        addBranchFormRef.resetFields()
      })
      .catch((err) => console.error({ err }))
  }

  return (
    <>
      <Helmet title="Тохиргоо" defer={false} />
      <Row gutter={[8, 8]} className="mt-4" style={{ minHeight: 50 }}>
        {subMenus.map((subMenu) => {
          return (
            <Col flex="200px" key={subMenu.dataIndex}>
              <Button
                type="link"
                className="px-0"
                onClick={async () => await setActiveSubMenu(subMenu.dataIndex)}
                style={{
                  fontSize: 18,
                  fontWeight: 500,
                  color: activeSubMenu === subMenu.dataIndex ? '#11427F' : '#333333',
                  borderBottom: activeSubMenu === subMenu.dataIndex ? '1px solid #11427F' : '',
                }}
              >
                {subMenu.name}
              </Button>
            </Col>
          )
        })}
        {activeSubMenu === 'branches' && (getLocalStorageItem('roles')?.includes('Owner') || getLocalStorageItem('roles')?.includes('Admin')) && (
          <Col flex="260px" className="ms-auto">
            <Button className="ant-btn btn-sb btn-sb-primary sb-shadow w-100" onClick={() => setAddBranchModalVisible(true)}>
              Салбар бүртгэх
            </Button>
          </Col>
        )}
      </Row>
      <Row gutter={[24, 8]} className="mt-3">
        {activeSubMenu === 'banners' ? (
          <Banners
            bannersInfo={bannersInfo}
            deleteBanner={(id) => handleDeleteBanner(id)}
            loading={loading}
            fetchBannerList={() => fetchTableData()}
            isOwnerOrAdmin={getLocalStorageItem('roles')?.includes('Owner') || getLocalStorageItem('roles')?.includes('Admin')}
          />
        ) : activeSubMenu === 'branches' ? (
          <Branches
            branchesInfo={branchesInfo}
            loading={loading}
            fetchBrancheList={() => fetchTableData()}
            staffList={staffList}
            isOwnerOrAdmin={getLocalStorageItem('roles')?.includes('Owner') || getLocalStorageItem('roles')?.includes('Admin')}
          />
        ) : activeSubMenu === 'shipmentTypes' ? (
          <ShipmentTypes isOwnerOrAdmin={getLocalStorageItem('roles')?.includes('Owner') || getLocalStorageItem('roles')?.includes('Admin')} />
        ) : activeSubMenu === 'messageTemplate' ? (
          <MessageTemplate isOwnerOrAdmin={getLocalStorageItem('roles')?.includes('Owner') || getLocalStorageItem('roles')?.includes('Admin')} />
        ) : activeSubMenu === 'emailTemplate' ? (
          <EmailTemplate isOwnerOrAdmin={getLocalStorageItem('roles')?.includes('Owner') || getLocalStorageItem('roles')?.includes('Admin')} />
        ) : (
          ''
        )}
      </Row>
      {addBranchModalVisible && (
        <BranchModal
          modalVisible={addBranchModalVisible}
          closeModal={() => {
            setAddBranchModalVisible(false)
            addBranchFormRef.resetFields()
          }}
          formRef={addBranchFormRef}
          handleSubmit={addBranch}
          isReceiveOnline={isReceiveOnline}
          setIsReceiveOnline={(value) => setIsReceiveOnline(value)}
          staffList={staffList}
        />
      )}
    </>
  )
}

export default Configuration
